.frm_form_field{
    border: none;
    outline: none;
    width: calc(50% - 2em);
    margin: 0.5em 1em !important;
    float: left;
    clear: none !important;
    font-family: 'Montserrat-Light' !important;
    input, select{
        font-family: 'Montserrat-Light' !important;
        width: 100% !important;
        font-size: 1em !important;
        padding: 0.5em 0 !important;
        appearance: none !important;
        border: none !important;
        border-color: transparent !important;
        box-shadow: none !important;
        border-bottom: 1px solid darken($grey, 50%) !important;
        background: transparent !important;
        border-radius: 0 !important;
        color: darken($grey, 50%) !important;
        outline: none !important;

    }
    select{
        background-image: url(images/arrowDown.png) !important;
        background-position: right center !important;
        background-repeat: no-repeat !important;
        background-size: 4% !important;
    }
    &.full{
        width: calc(100% - 2em);
    }
    textarea{
        font-family: 'Montserrat-Light' !important;
        width: 100% !important;
        font-size: 1em !important;
        padding: 0.5em !important;
        border: 1px solid darken($grey, 50%) !important;
        height: 150px !important;
        resize: none !important;
        outline: none !important;
        border-radius: 0px !important;
        &:focus{
            background-color: transparent !important;
            border-color: darken($grey, 50%) !important;
            box-shadow: none !important;
        }
    }
}
.frm_submit{
    width: calc(100% - 2em) !important;
    margin: 0.5em 1em !important;
    clear: none !important;
    button.frm_button_submit{
        width: 200px !important;
        float: right !important;
        background: $baseColor !important;
        color: white !important;
        font-family: 'Montserrat-Medium' !important;
        text-transform: uppercase !important;
        transition: all 0.3s !important;
        cursor: pointer !important;
        border: none !important;
        font-size: 1em !important;
        border-width: 1px !important;
        border-color: transparent !important;
        border-style: solid;
        border-radius: 0px !important;
        padding: 0.8em 0 !important;
        box-shadow: none !important;
        margin: 0 !important;
        margin-top: 0.5em !important;
        &:hover{
            background: $baseColorLight !important;
        }
    }
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: darken($grey, 50%)  !important;
  font-family: 'Montserrat-Light' !important;

}
::-moz-placeholder { /* Firefox 19+ */
  color: darken($grey, 50%)  !important;
  font-family: 'Montserrat-Light' !important;

}
:-ms-input-placeholder { /* IE 10+ */
  color: darken($grey, 50%)  !important;
  font-family: 'Montserrat-Light' !important;

}
:-moz-placeholder { /* Firefox 18- */
  color: darken($grey, 50%)  !important;
  font-family: 'Montserrat-Light' !important;

}

@media screen and (max-width: 700px){
    .frm_form_field{
        width: calc(100% - 2em) !important;
        float: none !important;
        margin-bottom: 1em !important;
        select{
            background-size: 2.5% !important;
        }
    }
}
