#whoiswho{
    margin: 4em auto;
    .content{
        column-count: 2;
        ul{
            break-inside: avoid-column;
            margin-bottom: 1em;
            list-style: none;
            background: $grey;
            padding: 1em;
            li{
                img{
                    margin: 0;
                }
                a{
                    color: $baseColor;
                }
                &:first-child{
                    list-style: none;
                    font-family: 'Montserrat-Medium';
                    margin-left: 0;
                    margin-bottom: 0.5em;
                    &:before{
                        display: none;
                    }
                }
                margin-left: 1em;
                &:before{
                    display: inline-block;
                    content: "\f0da";
                    font-family: FontAwesome;
                    margin-right: 0.5em;
                    color: $baseColorLight;
                }
                ul{
                    margin-bottom: 0.5em;
                    padding: 0;
                    li:first-child{
                        font-family: 'Montserrat-Light';
                        margin-left: 1em;
                        margin-bottom: 0;
                        &:before{
                            display: inline-block;
                            content: "\f0da";
                            font-family: FontAwesome;
                            margin-right: 0.5em;
                            color: $baseColorLight;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px){
    #whoiswho{
        .content{
            column-count: 1;
        }
    }
}
