#wpadminbar{
    display: none;
}
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: lighter;
    height: auto;
    outline: none;
}
html, body{
    width: 100%;
    height: 100%;
    font-size: 15px;
    font-family: 'Montserrat-Light';
    color: $fontColor;
}
.wrapper {
    min-height: calc(100% - 214px);
    overflow-x: hidden;
}
img{
    max-width: 100%;
    height: auto;
    border: 0;
}
a{
    text-decoration: none;
    outline: none;
    transition: all 0.5s;
    color: inherit;
}
.container{
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
}
.containerSmall{
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
}
p {
    margin-bottom: 1em;
}
.clearing{
    clear: both;
    display: block;
}

.titleRedLine{
    text-align: center;
    padding-bottom: 1em;
    position: relative;
    margin-bottom: 2em;
    &:after{
        width: 80px;
        height: 4px;
        background: $baseColor;
        content: '';
        display: block;
        position: absolute;
        bottom: 1em;
        left: 50%;
        transform: translateX(-50%);
    }
    h2{
        text-transform: uppercase;
        font-family: 'Montserrat-Bold';
        font-size: 1.7em;
    }
}

.slideshowlink{
    display: none;
}

.ngg-gallery-thumbnail-box {
    width: 25%;
}

.ngg-gallery-thumbnail {
    border: none !important;
    margin: 0 !important;
}

.ngg-navigation a.next:hover, .ngg-navigation a.page-numbers:hover, .ngg-navigation a.prev:hover, .ngg-navigation span.next:hover, .ngg-navigation span.page-numbers:hover, .ngg-navigation span.prev:hover {
    background-color: $baseColor !important;
}
a.prev{
    position: relative;
    &:hover{
        &:after{
            color: white;
        }
    }
    &:after{
        content: "\f0d9";
        font-family: FontAwesome;
        position: absolute;
        display: inline-block;
        z-index: 100;
        color: #871930;
        font-size: 1.5em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.ngg-navigation a.next:hover{
    color: $baseColor !important;
}
a.next{
    position: relative;
    color: white !important;
    content: '';
    &:hover{
        color: $baseColor !important;
        &:after{
            color: white;
        }
    }
    &:after{
        content: "\f0da";
        font-family: FontAwesome;
        position: absolute;
        display: inline-block;
        z-index: 100;
        color: #871930;
        font-size: 1.5em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.ngg-navigation {
    padding-bottom: 10px !important;
}

@media screen and (max-width: 1300px){
    .container{
        width: 95%;
    }
}

@media screen and (max-width: 900px){
    .containerSmall{
        width: 95%;
    }
    .ngg-gallery-thumbnail-box {
        width: 33.33%;
    }
}
