#icons{
    padding: 4em 0;
    .left{
        float: left;
        width: 40%;
    }
    .right{
        float: right;
        width: 55%;
        ul{
            li{
                margin-bottom: 1em;
                ul{
                    margin-left: 2em;
                }
            }
        }
    }
    .imageWrap{
        width: 180px;
        height: 180px;
        border: 4px solid $grey;
        margin: auto;
        margin-bottom: 1em;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .image{
            width: 60%;
            height: 60%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

@media screen and (max-width: 750px){
    #icons{
        .left{
            float: none;
            width: 100%;
        }
        .right{
            float: none;
            width: 90%;
            margin: auto;
        }
    }
}
