@font-face {
    font-family: 'Montserrat-Light';
    src:    url('fonts/Montserrat-Light.eot') format('embedded-opentype');
    src:    url('fonts/Montserrat-Light.woff') format('woff'),
            url('fonts/Montserrat-Light.ttf') format('truetype'),
            url('fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Regular';
    src:    url('fonts/Montserrat-Regular.eot') format('embedded-opentype');
    src:    url('fonts/Montserrat-Regular.woff') format('woff'),
            url('fonts/Montserrat-Regular.ttf') format('truetype'),
            url('fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Medium';
    src:    url('fonts/Montserrat-Medium.eot') format('embedded-opentype');
    src:    url('fonts/Montserrat-Medium.woff') format('woff'),
            url('fonts/Montserrat-Medium.ttf') format('truetype'),
            url('fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src:    url('fonts/Montserrat-Bold.eot') format('embedded-opentype');
    src:    url('fonts/Montserrat-Bold.woff') format('woff'),
            url('fonts/Montserrat-Bold.ttf') format('truetype'),
            url('fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}
