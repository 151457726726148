#imageCounter{
    padding: 6em 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    .content{
        position: relative;
        z-index: 50;
    }
    .overlay{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.4);
    }
    ul{
        list-style: none;
        display: block;
        li{
            color: $white;
            float: left;
            width: calc(25% - 2em);
            margin: 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            .image{
                float: left;
                width: 50px;
                height: 50px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                margin-right: 2em;
            }
            .right{
                float: left;
            }
            .number{
                font-size: 3.5em;
                font-family: 'Montserrat-Bold';
            }
            .title{
                text-transform: uppercase;
            }
        }
    }
}

@media screen and (max-width: 1060px){
    #imageCounter{
        ul{
            li{
                width: calc(50% - 2em);
            }
        }
    }
}

@media screen and (max-width: 600px){
    #imageCounter{
        padding: 2em 0;
        ul{
            li{
                width: calc(100% - 2em);
            }
        }
    }
}
