#zoeken{
    padding: 4em 0;
    form{
        input{
            width: calc(100% - 150px);
            float: left;
            appearance: none;
            padding: 0.5em 0;
            font-size: 1em;
            border: none;
            border-bottom: 1px solid darken($grey, 50%);

        }
        input.zoek{
            width: 150px;
            float: left;
            background: $baseColor;
            color: white;
            appearance: none;
            padding: 0.5em 1em;
            font-size: 1em;
            text-transform: uppercase;
            cursor: pointer;
            border: 1px solid $baseColor;
            &:hover{
                background: $baseColorLight;
                border: 1px solid $baseColorLight;
            }
        }
    }
    ul.searchgrid{
        list-style: none;
        display: block;
        li{
            float: left;
            width: calc(33.33% - 2em);
            margin: 1em;
            background: #fff;
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
            position: relative;
            transform: scale(1);
            transition: all 0.35s;
            word-wrap: break-word;
            overflow-wrap: break-word;
            position: relative;
            &:hover{
                transform: scale(1.03);
            }
            a.button{
                margin-top: 2em;
                width: calc(100% - 2em);
                display: block;
                background: $baseColor;
                text-align: center;
                color: $white;
                padding: 0.5em;
                bottom: 0;
                position: absolute;
                margin: 1em;
                &:hover{
                    background: $baseColorLight;
                }
            }
            .content{
                padding: 1em;
                margin-bottom: 50px;
                h4{
                    font-family: 'Montserrat-Medium';
                    text-transform: uppercase;
                    margin-bottom: 1em;
                }
                .eventdate{
                    width: 100%;
                    height: 150px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    background: $blue;
                    padding: 1em;
                    margin-bottom: 1em;
                    p{
                        text-transform: uppercase;
                        text-align: center;
                        font-family: 'Montserrat-Medium';
                        font-size: 1.5em;
                        margin: 0;
                        color: $white;
                    }
                }
                .image{
                    width: 100%;
                    height: 150px;
                    position: relative;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-bottom: 1em;
                }
                .logo{
                    background-image: url(images/logo.png);
                    width: 100%;
                    height: 150px;
                    position: relative;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-bottom: 1em;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px){
    #zoeken{
        ul.searchgrid{
            li{
                width: calc(50% - 2em);
            }
        }
    }
}

@media screen and (max-width: 750px){
    #zoeken{
        ul.searchgrid{
            li{
                width: calc(100% - 2em);
            }
        }
    }
}
