@media print {
    header, #asideMenu, footer,
    .wrapper form fieldset .send,
    a.printbutton,
    p.succes{
        display: none;
    }
    *{
        font-size: 0.95em;
    }
    .wrapper form fieldset:nth-child(2){
        margin-top: 100px;
        padding-top: 50px;
    }
    .wrapper form fieldset:nth-child(3){
        padding-top: 50px;
    }
    .wrapper form fieldset:nth-child(8){
        margin-top: 200px;
        padding-top: 50px;
    }
    .wrapper form fieldset:nth-child(9) .full:nth-child(6){
        margin-top: 200px;
        padding-top: 50px;
    }
}
