#waaromSMCB{
    padding: 4em 0;
    .titleRedLine{
        max-width: 700px;
        margin: auto;
        margin-bottom: 2em;
    }
    .text{
        max-width: 800px;
        margin: auto;
        margin-bottom: 2em;
        b,strong{
            font-family: 'Montserrat-Medium';
        }
        ul{
            margin-left: 2em;
        }
    }
    .content{
        ul{
            list-style: none;
            display: block;
            li{
                float: left;
                width: calc(25% - 2em);
                margin: 1em;
                text-align: center;
                position: relative;
                margin-bottom: 3em;
                a{
                    border-radius: 10px;
                    bottom: 0;
                    color: white;
                    padding: 0.5em 1em;
                    display: block;
                    position: absolute;
                    background: $baseColor;
                    left: 50%;
                    transform: translateX(-50%);
                    &:hover{
                        background: $baseColorLight;
                    }
                }
                .tekst{
                    margin-bottom: 4em;
                    display: block;
                }
                .imageWrap{
                    width: 150px;
                    height: 150px;
                    border: 4px solid $grey;
                    margin: auto;
                    margin-bottom: 1em;
                    border-radius: 50%;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .image{
                        width: 60%;
                        height: 60%;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
                .colorTitle{
                    font-family: 'Montserrat-Medium';
                    color: $baseColor;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px){
    #waaromSMCB{
        .content{
            ul{
                li{
                    width: calc(33.33% - 2em);
                }
            }
        }
    }
}

@media screen and (max-width: 650px){
    #waaromSMCB{
        .content{
            ul{
                li{
                    width: calc(50% - 2em);
                }
            }
        }
    }
}

@media screen and (max-width: 450px){
    #waaromSMCB{
        .content{
            ul{
                li{
                    width: calc(100% - 2em);
                }
            }
        }
    }
}
