#teskt.inschrijvingsformulier{
    fieldset{
        border: none;
        margin-bottom: 2em;

        .full{
            width: 100%;
        }
        .two-left{
            width: 47.5%;
            float: left;
            margin-right: 5%;
        }
        .two-right{
            width: 47.5%;
            float: left;
        }

        .three-left{
            width: 30%;
            float: left;
            margin-right: 5%;
        }
        .three-middle{
            width: 30%;
            float: left;
            margin-right: 5%;
        }
        .three-right{
            width: 30%;
            float: left;
        }
        h3{
            margin-bottom: 1em;
        }
        h4{
            padding: 5px 0;
            font-weight: 900;
            font-size: 1.1em;
            margin-bottom: 1em;
        }


        label{
            width: 100%;
            display: block;
            margin-bottom: 0.5em;
        }
        input{
            width: 100%;
            display: block;
            margin-bottom: 1em;
            font-size: 1em;
            font-family: 'Montserrat-Light';
            color: #454545;
            padding: 5px 10px;
        }
        textarea{
            width: 100%;
            display: block;
            margin-bottom: 1em;
            font-size: 1em;
            font-family: 'Montserrat-Light';
            color: #454545;
            padding: 5px 10px;
        }

        .radio{
            input{
                width: auto;
                display: inline-block;
                margin-right: 10px;
            }
        }
        input[type="submit"]{
            width: auto;
            display: block;
            float: right;
            margin-bottom: 1em;
            font-size: 1em;
            font-family: 'Montserrat-Light';
            padding: 10px 20px;
            background-color: $baseColor;
            border: none;
            color: $white;
            text-transform: uppercase;
            transition: all 0.5s;
            cursor: pointer;

            &:hover{
                background-color: $baseColorLight;
            }
        }
    }
    .italic {
        font-style: italic;
    }
    .smallText {
        font-size: 0.8em;
    }
}

@media screen and(max-width: 500px){
    #teskt.inschrijvingsformulier{
        fieldset{
            .two-left{
                width: 100%;
                margin-right: 0%;
            }
            .two-right{
                width: 100%;
            }

            .three-left{
                width: 100%;
                margin-right: 0%;
            }
            .three-middle{
                width: 100%;
                margin-right: 0%;
            }
            .three-right{
                width: 100%;
            }
        }
    }
}
