#teskt{
    padding: 4em 0;
    text-align: justify;
    h3{
        font-size: 1.2em;
        font-family: 'Montserrat-Medium';
        margin-top: 1.5em;
        margin-bottom: 0.2em;
        text-transform: uppercase;
    }
    a{
        color: $baseColor;
        font-family: 'Montserrat-Medium';
    }
    a.buttoninschrijven{
        border-radius: 10px;
        bottom: 0;
        color: white;
        padding: 0.5em 1em;
        display: inline-block;
        background: $baseColor;
        &:hover {
            background: $baseColorLight;
        }
    }
    ul{
        margin-left: 2em;
        margin-bottom: 1em;
    }
    b, strong{
        font-family: 'Montserrat-Medium';
    }
    #pictures{
        margin-top: 2em;
        ul{
            list-style: none;
            display: block;
            margin: 0;
            li{
                float: left;
                margin: 1em;
                width: calc(50% - 1em);
                height: 250px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                &:nth-child(odd){
                    margin-left: 0;
                }
                &:nth-child(even){
                    margin-right: 0;
                }
            }
        }
    }
    #picturesSmall{
        margin-top: 2em;
        ul{
            list-style: none;
            display: block;
            margin: 0;
            li{
                float: left;
                margin: 0.5em;
                width: calc(16% - 1em);
                height: 120px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                position: relative;
                a{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }
            }
        }
    }
}
.page-id-117{
    #teskt{
        #pictures{
            ul{
                li{
                    width: calc(33.33% - 2em);
                    background-size: contain;
                    height: 80px;
                    background-position: left center;
                    &:nth-child(1n){
                        margin-left: 0;
                        margin-right: 1em;
                    }
                    &:nth-child(2n){
                        margin: 1em;
                    }
                    &:nth-child(3n){
                        margin-right: 0;
                        margin-left: 1em;
                    }
                }
            }
        }
    }
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float:right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

@media screen and (max-width: 750px){
    #teskt{
        #pictures{
            ul{
                li{
                    float: none;
                    margin: 1em 0;
                    width: 100%;
                    height: 300px;
                }
            }
        }
    }
    .page-id-117{
        #teskt{
            #pictures{
                ul{
                    li{
                        float: left;
                        width: calc(50% - 2em);
                        height: 80px;
                        &:nth-child(1n){
                            margin-left: 0;
                            margin-right: 1em;
                        }
                        &:nth-child(2n){
                            margin-right: 0;
                            margin-left: 1em;
                        }
                        &:nth-child(3n){
                            margin-left: 0;
                            margin-right: 1em;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 650px){
    .alignright, .alignleft {
        float: none;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 1.5em;
        display: block;
    }
}

@media screen and (max-width: 500px){
    .page-id-117{
        #teskt{
            #pictures{
                ul{
                    li{
                        float: none;
                        width: calc(100% - 2em);
                        background-position: center;
                        height: 80px;
                        &:nth-child(1n){
                            margin: 1em;
                        }
                        &:nth-child(2n){
                            margin: 1em;
                        }
                        &:nth-child(3n){
                            margin: 1em;
                        }
                    }
                }
            }
        }
    }
}
