#contact{
    margin: 4em auto;
    #form{
        float: right;
        width: calc(55% - 2em);
        margin: 1em;
        display: block;
        padding: 1em;
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.20);

        h3{
            text-transform: uppercase;
            font-family: 'Montserrat-Medium';
            margin: 0.5em 1em;
        }
    }
    #map{
        float: left;
        width: calc(45% - 2em);
        margin: 1em;
        min-height: 400px;
        position: relative;
        .information{
            position: absolute;
            z-index: 100;
            background: rgba($baseColor, 0.9);
            padding: 1em;
            border-radius: 0px 15px 15px 0px;
            color: white;
            top: 50px;
            left: 0;
            font-family: 'Montserrat-Light';
            font-size: 0.9em;
            b{
                font-family: 'Montserrat-Medium';
            }
        }
    }
    .extraInformation{
        column-count: 2;
        margin: 1em;
        margin-top: 2em;
        b, strong{
            font-family: 'Montserrat-Medium';
        }
        ul{
            break-inside: avoid-column;
            margin-bottom: 1em;
            list-style: none;
            background: $grey;
            padding: 1em;
            li{
                img{
                    margin: 0;
                }
                a{
                    color: $baseColor;
                }
                &:first-child{
                    list-style: none;
                    font-family: 'Montserrat-Medium';
                    margin-left: 0;
                    margin-bottom: 0.5em;
                    &:before{
                        display: none;
                    }
                }
                margin-left: 1em;
                &:before{
                    display: inline-block;
                    content: "\f0da";
                    font-family: FontAwesome;
                    margin-right: 0.5em;
                    color: $baseColorLight;
                }
                ul{
                    margin-bottom: 0.5em;
                    padding: 0;
                    li:first-child{
                        font-family: 'Montserrat-Light';
                        margin-left: 1em;
                        margin-bottom: 0;
                        &:before{
                            display: inline-block;
                            content: "\f0da";
                            font-family: FontAwesome;
                            margin-right: 0.5em;
                            color: $baseColorLight;
                        }
                    }
                }
            }
        }
    }
}

.succes{
    margin-left: 1em;
    color: $green;
}
.error{
    margin-left: 1em;
    color: $darkOrange;
}


@media screen and (max-width: 1000px){
    #contact{
        #form{
            float: none;
            width: calc(100% - 2em);
        }
        #map{
            float: none;
            width: calc(100% - 2em);
        }
        .extraInformation{
            column-count: 1;
        }
    }
}

@media screen and (max-width: 700px){
    #contact{
        #form{
            fieldset{
                width: calc(100% - 2em);
                float: none;
                margin-bottom: 1em;
                select{
                    background-size: 2.5%;
                }
            }
        }
        #map{
            height: 350px !important;
        }
    }

}
