#topMenu{
    padding: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    position: relative;
    z-index: 1000;
    width: 100%;

    .left {
        i{
            margin-right: 0.7em;
        }
        position: absolute;
        left: 2em;
        top: 50%;
        transform: translateY(-50%);
        .inschrijvenbutton{
            display: block;
            padding: 0.5em;
            text-align: center;
            background: $baseColorLight;
            color: $white;
            border: 2px solid $baseColorLight;
            margin-top: 0.5em;
            &:hover, &.active{
                color: $baseColorLight;
                background: transparent;
            }
        }
    }
    .logo{
        text-align: center;
        img{
            max-width: 80px;
        }
        h2{
            text-transform: uppercase;
            font-family: 'Montserrat-Medium';
            margin-top: 0.5em;
        }
    }
    .switchSchool{
        position: absolute;
        top: -0.5em;
        right: 2em;
        background: $baseColor;
        border-radius: 10px;
        a{
            cursor: pointer;
            background-color: $white;
            color: $baseColor;
            text-transform: uppercase;
            font-size: 0.9em;
            padding: 1.2em 1em 0.7em 1em;
            border: 1px solid $baseColor;
            display: inline-block;
            border-radius: 10px;
            margin: 0;
            &.active{
                background-color: $baseColor;
                color: $white;
            }
            &:hover:nth-child(1){
                background-color: $baseColorLight;
                color: $white;
            }
        }
    }
}
nav{
    width: 100%;
    background: $baseColor;
    position: relative;
    z-index: 1000;
    ul.menu{
        display: block;
        list-style: none;
        li{
            float: left;
            width: 16.66%;
            text-align: center;
            position: relative;
            a{
                color: $white;
                padding: 1em 0;
                display: block;
                font-family: 'Montserrat-Light';
            }
            &:after{
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: scaleY(0);
                transform-origin: bottom center;
                background: $baseColorLight;
                z-index: -1;
                transition: transform 0.2s;
            }
            &:hover:after, &.current-page-ancestor:after, &.current-menu-item:after, &.current-page-parent:after{
                transform: scaleY(1);
            }
            &:hover{
                ul{
                    display: block !important;
                }
            }
            ul{
                width: 100%;
                background: $baseColor;
                transition: all 0.3s;
                list-style: none;
                display: none;
                position: absolute;
                li{
                    float: none;
                    width: 100%;
                    text-align: left;
                    position: relative;
                    display: block;
                    transition: all 0.3s;
                    a{
                        padding: 0.5em;
                        display: block;
                    }
                    &:hover, &.current-menu-item{
                        background: $baseColorLight;
                    }
                }
            }
        }
        li.menu-item-type-custom{
            &.current-page-ancestor:after, &.current-menu-item:after{
                transform: scaleY(0);
            }
            &:hover:after{
                transform: scaleY(1);
            }
        }
        li.menu-item-type-custom.active{
            &:after{
                transform: scaleY(1);
            }
        }
    }
}

#asideMenu{
    position: fixed;
    background: rgba(135, 25, 48, 0.9);
    text-align: center;
    padding: 1em;
    padding-right: 2em;
    right: -1em;
    margin-top: 4em;
    border-radius: 10px;
    z-index: 900;
    img{
        margin: 0.3em 0;
    }
}
.triggerwrap{
    width: 100%;
    padding: 1em;
    display: none;
}

#trigger{
    display: none;
    float: right;
    width: 20px;
    height: 15px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    margin-left: 50px;
    color: $white;
    .tekst{
        position: absolute;
        left: -50px;
        text-transform: uppercase;
        font-size: 1em;
        top: -3px;
        margin-left: -0.5em;
  }
  span{
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      margin-top: 1px;
      background: $white;
  }
  span:nth-child(2) {
      top: 0px;
  }
  span:nth-child(3), span:nth-child(4) {
      top: 5px;
  }
  span:nth-child(5) {
      top: 10px;
  }
}
#trigger.open{
    span:nth-child(2) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
    span:nth-child(3) {
        transform: rotate(45deg);
    }
    span:nth-child(4) {
        transform: rotate(-45deg);
    }
    span:nth-child(5) {
        top: 18px;
        width: 0%;
        left: 50%;
    }
}

@media screen and (min-width: 900px){
    nav{
        ul.menu{
            display: block !important;
        }
    }
}

@media screen and (max-width: 1000px){
    #topMenu .left {
        position: absolute;
        top: 1em;
        transform: translateY(0);
    }
}

@media screen and (max-width: 900px){
    #trigger{
        display: block;
    }
    .triggerwrap{
        display: block;
        border-bottom: 1px solid $baseColorLight;
    }
    nav{
        .container{
            width: 100%;
        }
        ul.menu{
            display: none;
            li{
                float: none;
                width: 100%;
                display: block;
                text-align: center;
                position: relative;
                a{
                    color: $white;
                    padding: 1em 0;
                    display: inline-block;
                    font-family: 'Montserrat-Light';
                }
                &:after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transform: scaleY(0);
                    transform-origin: bottom center;
                    background: $baseColorLight;
                    z-index: -1;
                    transition: transform 0.2s;
                }
                &:hover:after{
                    transform: scaleY(1);
                }
                ul{
                    display: block;
                    position: static;
                    li{
                        font-size: 0.8em;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px){
    #asideMenu{
        padding: 0.5em;
        padding-right: 1em;
        right: -0.5em;
        top: 5em;
        img{
            width: 70%;
        }
    }
}

@media screen and (max-width: 650px){
    #topMenu{
        .left {
            position: absolute;
            left: 1em;
            top: 5px;
        }
        .logo{
            margin-top: 2.5em;
        }
    }
}

@media screen and (max-width: 505px){
    #topMenu{
        display: block;
        text-align: center;
        .left {
            position: static;
            width: calc(100% - 4em);
            margin: 4em 2em 2em 2em;
        }
        .logo{
            position: relative;
            width: 100%;
            margin-top: 1.5em;
        }
    }
}
