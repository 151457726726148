.headerImage{
    position: relative;
    width: 100%;
    height: 400px;
    background: $gradientstart;
    background: -moz-linear-gradient(top, $gradientstart 0%, $gradientend 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, $gradientstart), color-stop(100%, $gradientend));
    background: -webkit-linear-gradient(top, $gradientstart 0%, $gradientend 100%);
    background: -o-linear-gradient(top, $gradientstart 0%, $gradientend 100%);
    background: -ms-linear-gradient(top, $gradientstart 0%, $gradientend 100%);
    background: linear-gradient(to bottom, $gradientstart 0%, $gradientend 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradientstart', endColorstr='$gradientend', GradientType=0 );
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    h1{
        text-transform: uppercase;
        font-family: 'Montserrat-Medium';
        font-size: 4em;
        color: $white;
        display: block;
        margin-top: -1em;
        text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
        position: relative;
        z-index: 100;
        b, strong{
            margin-left: 1em;
        }
    }
}



@media screen and (max-width: 600px){
    .headerImage{
        h1{
            font-size: 3em;
        }
    }
}

@media screen and (max-width: 450px){
    .headerImage{
        h1{
            font-size: 2em;
        }
    }
}

@media screen and (max-width: 300px){
    .headerImage{
        h1{
            font-size: 1.5em;
        }
    }
}
