.subPages{
    list-style: none;
    display: block;
    li{
        float: left;
        margin: 0.5em;
        width: calc(50% - 1em);
        background: $grey;
        text-align: center;
        transition: 0.3s all;
        a{
            display: block;
            padding: 1.5em 1em;
            transition: none;
            text-transform: uppercase;
            font-family: 'Montserrat-Medium';
        }
        border-bottom: 4px solid $baseColor;
        &:hover{
            background: $baseColor;
            color: white;
        }
        &:nth-child(4n){
            border-bottom: 4px solid $baseColorLight;
            &:hover{
                background: $baseColorLight;
                color: white;
            }
        }
        &:nth-child(4n+1){
            border-bottom: 4px solid $baseColorLight;
            &:hover{
                background: $baseColorLight;
                color: white;
            }
        }
    }
}

@media screen and (max-width: 700px){
    .subPages{
        li{
            width: calc(100% - 1em);
        }
    }
}
