#kalender{
    padding: 4em 0;
    text-align: justify;
    iframe {
        height: 800px;
        width: 100%;
    }
}


@media screen and(max-width: 991px){
    #kalender{
        iframe {
            height: 700px;
        }
    }
}

@media screen and(max-width: 768px){
    #kalender{
        iframe {
            height: 600px;
        }
    }
}

@media screen and(max-width: 650px){
    #kalender{
        iframe {
            height: 500px;
        }
    }
}

@media screen and(max-width: 500px){
    #kalender{
        iframe {
            height: 400px;
        }
    }
}
