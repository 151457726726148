$fontColor: #454545;

$gradientstart: #902F3E;
$gradientend: #B67781;

$baseColor: #871930;
$baseColorLight: #A4565D;

$blue: #017FB3;
$yellow: #D40543;
$orange: #F77D24;
$darkOrange: #EA7965;
$green: #88C9A3;

$white: #ffffff;
$balck: #000000;

$grey: #F2F2F2;
