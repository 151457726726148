#single{
    margin: 4em auto;
    .backto{
        color: $baseColorLight;
        font-size: 0.9em;
        margin-bottom: 0.5em;
        display: inline-block;
    }
    .titleRedLine h2{
        padding-bottom: 0.5em;
    }
    &.picture{
        .content{
            text-align: center;
        }
    }
    b, strong{
        font-family: 'Montserrat-Medium';
    }
    a{
        color: $baseColor;
    }
    #pictures{
        ul{
            list-style: none;
            display: block;
            li{
                float: left;
                margin: 0.5em;
                width: calc(16.66% - 1em);
                height: 120px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                position: relative;
                a{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 900px){
    #single{
        #pictures{
            ul{
                li{
                    width: calc(25% - 1em);
                }
            }
        }
    }
}
@media screen and (max-width: 600px){
    #single{
        #pictures{
            ul{
                li{
                    width: calc(33.33% - 1em);
                }
            }
        }
    }
}

@media screen and (max-width: 450px){
    #single{
        #pictures{
            ul{
                li{
                    width: calc(50% - 1em);
                }
            }
        }
    }
}
