footer .redfoooter{
    background: $gradientstart;
    padding: 2em 0;
    color: $white;
    .container{
        display: flex;
        align-items: center;
        justify-content: center;
        .left{
            text-align: center;
            width: 50%;
            border-right: 1px solid #A4565D;
            img{
                max-width: 80px;
                margin-bottom: 0.5em;
            }
        }
        .right{
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

footer .subfooter{
    text-align: center;
    p{
        margin-bottom: 0;
        padding: 0.5em 0;
    }
    a{
        color: $baseColor;
        font-family: 'Montserrat-Medium';
    }
}


@media screen and (max-width: 800px){
    footer .redfoooter{
        .container{
            display: block;
            .left{
                width: 100%;
                border-right: none;
                border-bottom: 1px solid #A4565D;
                padding-bottom: 1em;
            }
            .right{
                width: 100%;
                padding-top: 1em;
                text-align: center;
            }
        }
    }
}
