#downloads{
    margin: 4em auto;
    ul{
        list-style: none;
        display: block;
        li{
            float: left;
            margin: 0.5em;
            width: calc(50% - 1em);
            background: $grey;
            text-align: center;
            transition: 0.3s all;
            &:before{
                display: block;
                content: "\f019";
                font-family: FontAwesome;
                margin: 1em 1em 0 1em;
                font-size: 2em;
                color: $baseColor;
            }
            &:nth-child(4n){
                &:before{
                    color: $baseColorLight;
                }
            }
            &:nth-child(4n+1){
                &:before{
                    color: $baseColorLight;
                }
            }
            a{
                display: block;
                padding: 1.5em 1em;
                transition: none;
                text-transform: uppercase;
                font-family: 'Montserrat-Medium';
            }
        }
    }
}

@media screen and (max-width: 750px){
    #downloads{
        ul{
            li{
                width: calc(100% - 1em);
            }
        }
    }
}
