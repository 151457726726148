#news.notHome{
    margin-top: 4em;
}
#news{
    margin-top: -6em;
    margin-bottom: 4em;
    .linkbutton{
        text-align: center;
        margin-bottom: 1em;
        a.button{
            padding: 1em 2em;
            display: inline-block;
            color: $white;
            border-radius: 15px;
            background: $baseColor;
            border-bottom: 5px solid darken($baseColor, 10%);
            margin-right: 1em;
            position: relative;
            margin-bottom: 1em;
            &:hover, &.active{
                background: darken($baseColor, 10%);
                border-bottom: 0;
                top: 5px;
            }
            &.Evenementen{
                background: $blue;
                border-bottom: 5px solid darken($blue, 10%);
                &:hover, &.active{
                    background: darken($blue, 10%);
                    border-bottom: 0;
                    top: 5px;
                }
            }
            &.Foto{
                background: $yellow;
                border-bottom: 5px solid darken($yellow, 10%);
                &:hover, &.active{
                    background: darken($yellow, 10%);
                    border-bottom: 0;
                    top: 5px;
                }
            }
            &.Nieuws{
                background: $orange;
                border-bottom: 5px solid darken($orange, 10%);
                &:hover, &.active{
                    background: darken($orange, 10%);
                    border-bottom: 0;
                    top: 5px;
                }
            }
        }
    }
    ul{
        display: block;
        list-style: none;
        li{
            width: calc(25% - 2em);
            float: left;
            background: $white;
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
            margin: 1em;
            position: relative;
            padding-bottom: 1em;
            transform: scale(1);
            transition: all 0.35s;
            &:hover{
                transform: scale(1.03);
            }
            a{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .image{
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 100%;
                height: 150px;
                position: relative;
            }
            p.small{
                padding: 1em 1em 0 1em;
                margin-bottom: 0;
                font-size: 0.8em;
                text-transform: uppercase;
                font-family: 'Montserrat-Medium';
                position: relative;
                padding-left: 45px;
                &:before{
                    width: 25px;
                    height: 3px;
                    content: '';
                    display: block;
                    position: absolute;
                    top: 17px;
                    left: 1em;
                }
            }
            .content{
                padding: 1em;
                word-wrap: break-word;
                overflow-wrap: break-word;
                h4{
                    text-transform: uppercase;
                    font-family: 'Montserrat-Medium';
                    margin-bottom: 1em;
                }
            }
            &.news{
                border-top: 6px solid $orange;
                p.small{
                    color: $orange;
                    &:before{
                        background: $orange;
                    }
                }
            }
            &.picture{
                border-top: 6px solid $yellow;
                p.small{
                    color: $yellow;
                    &:before{
                        background: $yellow;
                    }
                }
            }
            &.event{
                border-top: 6px solid $blue;
                p.small{
                    color: $blue;
                    &:before{
                        background: $blue;
                    }
                }
                .eventdate{
                    width: 100%;
                    height: 150px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    background: $blue;
                    p{
                        text-transform: uppercase;
                        text-align: center;
                        font-family: 'Montserrat-Medium';
                        font-size: 1.5em;
                        color: $white;
                    }
                }
            }
        }
    }
    .allNews{
        text-align: center;
        display: inline-block;
        padding: 0.5em 1em;
        text-transform: uppercase;
        background: $baseColor;
        color: $white;
        border: 2px solid $baseColor;
        margin-top: 3em;
        i{
            margin-left: 0.5em;
        }
        &:hover{
            color: $baseColor;
            background: $white;
        }
    }
}

@media screen and (max-width: 1200px){
    #news{
        ul{
            li{
                width: calc(33.33% - 2em);
            }
        }
    }
}
@media screen and (max-width: 1000px){
    #news{
        ul{
            li{
                width: calc(50% - 2em);
            }
        }
    }
}
@media screen and (max-width: 700px){
    #news{
        ul{
            li{
                width: calc(100% - 2em);
                padding-bottom: 0;
                .image{
                    width: 50%;
                    height: 250px;
                    float: left;
                }
                .content{
                    float: right;
                    width: 50%;
                }
                p.small{
                    width: 50%;
                    float: right;
                }
                &.event{
                    .eventdate{
                        width: 50%;
                        height: 250px;
                        float: left;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px){
    #news{
        ul{
            li{
                width: calc(100% - 2em);
                padding-bottom: 0;
                .image{
                    width: 100%;
                    height: 150px;
                    float: none;
                }
                .content{
                    float: none;
                    width: 100%;
                }
                p.small{
                    width: 100%;
                    float: none;
                }
                &.event{
                    .eventdate{
                        width: 100%;
                        height: 150px;
                        float: none;
                    }
                }
            }
        }
    }
}
