#attachmentsTekst{
    margin-top: 2em;
    ul{
        list-style: none;
        display: block;
        margin: 0;
        li{
            border-top: 2px solid $grey;
            h2{
                text-align: center;
                text-transform: uppercase;
                font-family: 'Montserrat-Medium';
                margin-top: 1em;
            }
            .image{
                width: 100%;
                margin: 1em;
                height: 300px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                margin-bottom: 2em;
            }
            .content{
                width: 100%;
                margin: 1em;
            }
        }
    }
}
